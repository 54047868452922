import React from "react";
import PropTypes from "prop-types";

function Payment({ size, primaryColor, testId }) {
  return (
    <svg
      css={{
        display: "block",
      }}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      focusable="false"
      role="img"
      data-testid={testId}
    >
      <path
        d="M9.95 5.642a11.99 11.99 0 0113.393.866l-.015-.631a1 1 0 01.977-1.023c.52-.046 1.01.424 1.023.977l.07 3.006c.006.308-.127.59-.346.779l-.024.02a1.031 1.031 0 01-.666.24l-2.992.43a1.002 1.002 0 01-1.133-.846 1.001 1.001 0 01.848-1.133l1.13-.162a9.987 9.987 0 00-11.257-.796 9.988 9.988 0 00-4.34 12.086 9.99 9.99 0 0011.137 6.393 9.989 9.989 0 008.247-9.844 1 1 0 012 0 11.987 11.987 0 01-9.896 11.813 11.983 11.983 0 01-13.365-7.672A11.987 11.987 0 019.95 5.642zm6.081 1.861a1 1 0 011 1v1.1c.99.2 1.618.677 1.99 1.095 1.151 1.29.984 3.07.96 3.27a.992.992 0 01-1.107.88 1 1 0 01-.88-1.103c.03-.3.005-1.196-.472-1.723-.315-.349-.803-.519-1.492-.519-1.843 0-2.012 1.314-2.02 1.717.007.407.172 1.783 1.992 1.783 1.197 0 2.166.363 2.88 1.08 1.085 1.087 1.112 2.552 1.111 2.715v.027c-.003.245-.07 1.572-1.085 2.59-.498.498-1.132.816-1.877.972v1.116a1 1 0 11-2 0v-1.089c-1.022-.193-1.669-.679-2.049-1.105-1.15-1.29-.983-3.07-.96-3.27a1 1 0 011.988.224c-.03.303-.004 1.196.471 1.722.315.35.803.518 1.492.518 1.843 0 2.012-1.313 2.02-1.715-.007-.408-.172-1.785-1.99-1.785-1.198 0-2.168-.363-2.88-1.078-1.086-1.088-1.113-2.553-1.113-2.716v-.027c.005-.245.072-1.573 1.086-2.59.51-.513 1.164-.838 1.935-.988v-1.1a1 1 0 011-1z"
        fill={primaryColor}
        fillRule="nonzero"
      />
    </svg>
  );
}

Payment.propTypes = {
  size: PropTypes.string.isRequired,
  primaryColor: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

export default Payment;
