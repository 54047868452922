// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-colors-accessibility-js": () => import("./../../../src/pages/colors/accessibility.js" /* webpackChunkName: "component---src-pages-colors-accessibility-js" */),
  "component---src-pages-colors-index-js": () => import("./../../../src/pages/colors/index.js" /* webpackChunkName: "component---src-pages-colors-index-js" */),
  "component---src-pages-colors-resources-mdx": () => import("./../../../src/pages/colors/resources.mdx" /* webpackChunkName: "component---src-pages-colors-resources-mdx" */),
  "component---src-pages-components-accordion-index-js": () => import("./../../../src/pages/components/accordion/index.js" /* webpackChunkName: "component---src-pages-components-accordion-index-js" */),
  "component---src-pages-components-accordion-resources-mdx": () => import("./../../../src/pages/components/accordion/resources.mdx" /* webpackChunkName: "component---src-pages-components-accordion-resources-mdx" */),
  "component---src-pages-components-accordion-usage-mdx": () => import("./../../../src/pages/components/accordion/usage.mdx" /* webpackChunkName: "component---src-pages-components-accordion-usage-mdx" */),
  "component---src-pages-components-button-index-js": () => import("./../../../src/pages/components/button/index.js" /* webpackChunkName: "component---src-pages-components-button-index-js" */),
  "component---src-pages-components-button-resources-mdx": () => import("./../../../src/pages/components/button/resources.mdx" /* webpackChunkName: "component---src-pages-components-button-resources-mdx" */),
  "component---src-pages-components-button-usage-mdx": () => import("./../../../src/pages/components/button/usage.mdx" /* webpackChunkName: "component---src-pages-components-button-usage-mdx" */),
  "component---src-pages-components-checkbox-group-index-js": () => import("./../../../src/pages/components/checkbox-group/index.js" /* webpackChunkName: "component---src-pages-components-checkbox-group-index-js" */),
  "component---src-pages-components-checkbox-group-resources-mdx": () => import("./../../../src/pages/components/checkbox-group/resources.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-group-resources-mdx" */),
  "component---src-pages-components-checkbox-group-usage-mdx": () => import("./../../../src/pages/components/checkbox-group/usage.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-group-usage-mdx" */),
  "component---src-pages-components-checkbox-index-js": () => import("./../../../src/pages/components/checkbox/index.js" /* webpackChunkName: "component---src-pages-components-checkbox-index-js" */),
  "component---src-pages-components-checkbox-resources-mdx": () => import("./../../../src/pages/components/checkbox/resources.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-resources-mdx" */),
  "component---src-pages-components-checkbox-usage-mdx": () => import("./../../../src/pages/components/checkbox/usage.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-usage-mdx" */),
  "component---src-pages-components-container-index-js": () => import("./../../../src/pages/components/container/index.js" /* webpackChunkName: "component---src-pages-components-container-index-js" */),
  "component---src-pages-components-container-resources-mdx": () => import("./../../../src/pages/components/container/resources.mdx" /* webpackChunkName: "component---src-pages-components-container-resources-mdx" */),
  "component---src-pages-components-container-usage-mdx": () => import("./../../../src/pages/components/container/usage.mdx" /* webpackChunkName: "component---src-pages-components-container-usage-mdx" */),
  "component---src-pages-components-date-picker-index-js": () => import("./../../../src/pages/components/date-picker/index.js" /* webpackChunkName: "component---src-pages-components-date-picker-index-js" */),
  "component---src-pages-components-date-picker-resources-mdx": () => import("./../../../src/pages/components/date-picker/resources.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-resources-mdx" */),
  "component---src-pages-components-date-picker-usage-mdx": () => import("./../../../src/pages/components/date-picker/usage.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-usage-mdx" */),
  "component---src-pages-components-divider-index-js": () => import("./../../../src/pages/components/divider/index.js" /* webpackChunkName: "component---src-pages-components-divider-index-js" */),
  "component---src-pages-components-divider-resources-mdx": () => import("./../../../src/pages/components/divider/resources.mdx" /* webpackChunkName: "component---src-pages-components-divider-resources-mdx" */),
  "component---src-pages-components-divider-usage-mdx": () => import("./../../../src/pages/components/divider/usage.mdx" /* webpackChunkName: "component---src-pages-components-divider-usage-mdx" */),
  "component---src-pages-components-dropdown-index-js": () => import("./../../../src/pages/components/dropdown/index.js" /* webpackChunkName: "component---src-pages-components-dropdown-index-js" */),
  "component---src-pages-components-dropdown-resources-mdx": () => import("./../../../src/pages/components/dropdown/resources.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-resources-mdx" */),
  "component---src-pages-components-dropdown-usage-mdx": () => import("./../../../src/pages/components/dropdown/usage.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-usage-mdx" */),
  "component---src-pages-components-flex-index-js": () => import("./../../../src/pages/components/flex/index.js" /* webpackChunkName: "component---src-pages-components-flex-index-js" */),
  "component---src-pages-components-flex-resources-mdx": () => import("./../../../src/pages/components/flex/resources.mdx" /* webpackChunkName: "component---src-pages-components-flex-resources-mdx" */),
  "component---src-pages-components-flex-usage-mdx": () => import("./../../../src/pages/components/flex/usage.mdx" /* webpackChunkName: "component---src-pages-components-flex-usage-mdx" */),
  "component---src-pages-components-footer-index-js": () => import("./../../../src/pages/components/footer/index.js" /* webpackChunkName: "component---src-pages-components-footer-index-js" */),
  "component---src-pages-components-footer-resources-mdx": () => import("./../../../src/pages/components/footer/resources.mdx" /* webpackChunkName: "component---src-pages-components-footer-resources-mdx" */),
  "component---src-pages-components-footer-usage-mdx": () => import("./../../../src/pages/components/footer/usage.mdx" /* webpackChunkName: "component---src-pages-components-footer-usage-mdx" */),
  "component---src-pages-components-form-index-js": () => import("./../../../src/pages/components/form/index.js" /* webpackChunkName: "component---src-pages-components-form-index-js" */),
  "component---src-pages-components-form-resources-mdx": () => import("./../../../src/pages/components/form/resources.mdx" /* webpackChunkName: "component---src-pages-components-form-resources-mdx" */),
  "component---src-pages-components-form-usage-mdx": () => import("./../../../src/pages/components/form/usage.mdx" /* webpackChunkName: "component---src-pages-components-form-usage-mdx" */),
  "component---src-pages-components-frequency-index-js": () => import("./../../../src/pages/components/frequency/index.js" /* webpackChunkName: "component---src-pages-components-frequency-index-js" */),
  "component---src-pages-components-frequency-resources-mdx": () => import("./../../../src/pages/components/frequency/resources.mdx" /* webpackChunkName: "component---src-pages-components-frequency-resources-mdx" */),
  "component---src-pages-components-frequency-usage-mdx": () => import("./../../../src/pages/components/frequency/usage.mdx" /* webpackChunkName: "component---src-pages-components-frequency-usage-mdx" */),
  "component---src-pages-components-grid-index-js": () => import("./../../../src/pages/components/grid/index.js" /* webpackChunkName: "component---src-pages-components-grid-index-js" */),
  "component---src-pages-components-grid-resources-mdx": () => import("./../../../src/pages/components/grid/resources.mdx" /* webpackChunkName: "component---src-pages-components-grid-resources-mdx" */),
  "component---src-pages-components-grid-usage-mdx": () => import("./../../../src/pages/components/grid/usage.mdx" /* webpackChunkName: "component---src-pages-components-grid-usage-mdx" */),
  "component---src-pages-components-header-index-js": () => import("./../../../src/pages/components/header/index.js" /* webpackChunkName: "component---src-pages-components-header-index-js" */),
  "component---src-pages-components-header-resources-mdx": () => import("./../../../src/pages/components/header/resources.mdx" /* webpackChunkName: "component---src-pages-components-header-resources-mdx" */),
  "component---src-pages-components-header-usage-mdx": () => import("./../../../src/pages/components/header/usage.mdx" /* webpackChunkName: "component---src-pages-components-header-usage-mdx" */),
  "component---src-pages-components-icon-index-js": () => import("./../../../src/pages/components/icon/index.js" /* webpackChunkName: "component---src-pages-components-icon-index-js" */),
  "component---src-pages-components-icon-resources-mdx": () => import("./../../../src/pages/components/icon/resources.mdx" /* webpackChunkName: "component---src-pages-components-icon-resources-mdx" */),
  "component---src-pages-components-icon-usage-mdx": () => import("./../../../src/pages/components/icon/usage.mdx" /* webpackChunkName: "component---src-pages-components-icon-usage-mdx" */),
  "component---src-pages-components-input-index-js": () => import("./../../../src/pages/components/input/index.js" /* webpackChunkName: "component---src-pages-components-input-index-js" */),
  "component---src-pages-components-input-resources-mdx": () => import("./../../../src/pages/components/input/resources.mdx" /* webpackChunkName: "component---src-pages-components-input-resources-mdx" */),
  "component---src-pages-components-input-usage-mdx": () => import("./../../../src/pages/components/input/usage.mdx" /* webpackChunkName: "component---src-pages-components-input-usage-mdx" */),
  "component---src-pages-components-link-index-js": () => import("./../../../src/pages/components/link/index.js" /* webpackChunkName: "component---src-pages-components-link-index-js" */),
  "component---src-pages-components-link-resources-mdx": () => import("./../../../src/pages/components/link/resources.mdx" /* webpackChunkName: "component---src-pages-components-link-resources-mdx" */),
  "component---src-pages-components-link-usage-mdx": () => import("./../../../src/pages/components/link/usage.mdx" /* webpackChunkName: "component---src-pages-components-link-usage-mdx" */),
  "component---src-pages-components-list-index-js": () => import("./../../../src/pages/components/list/index.js" /* webpackChunkName: "component---src-pages-components-list-index-js" */),
  "component---src-pages-components-list-resources-mdx": () => import("./../../../src/pages/components/list/resources.mdx" /* webpackChunkName: "component---src-pages-components-list-resources-mdx" */),
  "component---src-pages-components-list-usage-mdx": () => import("./../../../src/pages/components/list/usage.mdx" /* webpackChunkName: "component---src-pages-components-list-usage-mdx" */),
  "component---src-pages-components-loading-icon-index-js": () => import("./../../../src/pages/components/loading-icon/index.js" /* webpackChunkName: "component---src-pages-components-loading-icon-index-js" */),
  "component---src-pages-components-loading-icon-resources-mdx": () => import("./../../../src/pages/components/loading-icon/resources.mdx" /* webpackChunkName: "component---src-pages-components-loading-icon-resources-mdx" */),
  "component---src-pages-components-loading-icon-usage-mdx": () => import("./../../../src/pages/components/loading-icon/usage.mdx" /* webpackChunkName: "component---src-pages-components-loading-icon-usage-mdx" */),
  "component---src-pages-components-message-index-js": () => import("./../../../src/pages/components/message/index.js" /* webpackChunkName: "component---src-pages-components-message-index-js" */),
  "component---src-pages-components-message-resources-mdx": () => import("./../../../src/pages/components/message/resources.mdx" /* webpackChunkName: "component---src-pages-components-message-resources-mdx" */),
  "component---src-pages-components-message-usage-mdx": () => import("./../../../src/pages/components/message/usage.mdx" /* webpackChunkName: "component---src-pages-components-message-usage-mdx" */),
  "component---src-pages-components-placeholder-index-js": () => import("./../../../src/pages/components/placeholder/index.js" /* webpackChunkName: "component---src-pages-components-placeholder-index-js" */),
  "component---src-pages-components-placeholder-resources-mdx": () => import("./../../../src/pages/components/placeholder/resources.mdx" /* webpackChunkName: "component---src-pages-components-placeholder-resources-mdx" */),
  "component---src-pages-components-placeholder-usage-mdx": () => import("./../../../src/pages/components/placeholder/usage.mdx" /* webpackChunkName: "component---src-pages-components-placeholder-usage-mdx" */),
  "component---src-pages-components-radio-group-index-js": () => import("./../../../src/pages/components/radio-group/index.js" /* webpackChunkName: "component---src-pages-components-radio-group-index-js" */),
  "component---src-pages-components-radio-group-resources-mdx": () => import("./../../../src/pages/components/radio-group/resources.mdx" /* webpackChunkName: "component---src-pages-components-radio-group-resources-mdx" */),
  "component---src-pages-components-radio-group-usage-mdx": () => import("./../../../src/pages/components/radio-group/usage.mdx" /* webpackChunkName: "component---src-pages-components-radio-group-usage-mdx" */),
  "component---src-pages-components-select-index-js": () => import("./../../../src/pages/components/select/index.js" /* webpackChunkName: "component---src-pages-components-select-index-js" */),
  "component---src-pages-components-select-resources-mdx": () => import("./../../../src/pages/components/select/resources.mdx" /* webpackChunkName: "component---src-pages-components-select-resources-mdx" */),
  "component---src-pages-components-select-usage-mdx": () => import("./../../../src/pages/components/select/usage.mdx" /* webpackChunkName: "component---src-pages-components-select-usage-mdx" */),
  "component---src-pages-components-shadow-container-index-js": () => import("./../../../src/pages/components/shadow-container/index.js" /* webpackChunkName: "component---src-pages-components-shadow-container-index-js" */),
  "component---src-pages-components-shadow-container-resources-mdx": () => import("./../../../src/pages/components/shadow-container/resources.mdx" /* webpackChunkName: "component---src-pages-components-shadow-container-resources-mdx" */),
  "component---src-pages-components-shadow-container-usage-mdx": () => import("./../../../src/pages/components/shadow-container/usage.mdx" /* webpackChunkName: "component---src-pages-components-shadow-container-usage-mdx" */),
  "component---src-pages-components-stack-index-js": () => import("./../../../src/pages/components/stack/index.js" /* webpackChunkName: "component---src-pages-components-stack-index-js" */),
  "component---src-pages-components-stack-resources-mdx": () => import("./../../../src/pages/components/stack/resources.mdx" /* webpackChunkName: "component---src-pages-components-stack-resources-mdx" */),
  "component---src-pages-components-stack-usage-mdx": () => import("./../../../src/pages/components/stack/usage.mdx" /* webpackChunkName: "component---src-pages-components-stack-usage-mdx" */),
  "component---src-pages-components-stepper-index-js": () => import("./../../../src/pages/components/stepper/index.js" /* webpackChunkName: "component---src-pages-components-stepper-index-js" */),
  "component---src-pages-components-stepper-resources-mdx": () => import("./../../../src/pages/components/stepper/resources.mdx" /* webpackChunkName: "component---src-pages-components-stepper-resources-mdx" */),
  "component---src-pages-components-stepper-usage-mdx": () => import("./../../../src/pages/components/stepper/usage.mdx" /* webpackChunkName: "component---src-pages-components-stepper-usage-mdx" */),
  "component---src-pages-components-sticky-index-js": () => import("./../../../src/pages/components/sticky/index.js" /* webpackChunkName: "component---src-pages-components-sticky-index-js" */),
  "component---src-pages-components-sticky-resources-mdx": () => import("./../../../src/pages/components/sticky/resources.mdx" /* webpackChunkName: "component---src-pages-components-sticky-resources-mdx" */),
  "component---src-pages-components-sticky-usage-mdx": () => import("./../../../src/pages/components/sticky/usage.mdx" /* webpackChunkName: "component---src-pages-components-sticky-usage-mdx" */),
  "component---src-pages-components-text-index-js": () => import("./../../../src/pages/components/text/index.js" /* webpackChunkName: "component---src-pages-components-text-index-js" */),
  "component---src-pages-components-text-resources-mdx": () => import("./../../../src/pages/components/text/resources.mdx" /* webpackChunkName: "component---src-pages-components-text-resources-mdx" */),
  "component---src-pages-components-text-usage-mdx": () => import("./../../../src/pages/components/text/usage.mdx" /* webpackChunkName: "component---src-pages-components-text-usage-mdx" */),
  "component---src-pages-components-textarea-index-js": () => import("./../../../src/pages/components/textarea/index.js" /* webpackChunkName: "component---src-pages-components-textarea-index-js" */),
  "component---src-pages-components-textarea-resources-mdx": () => import("./../../../src/pages/components/textarea/resources.mdx" /* webpackChunkName: "component---src-pages-components-textarea-resources-mdx" */),
  "component---src-pages-components-textarea-usage-mdx": () => import("./../../../src/pages/components/textarea/usage.mdx" /* webpackChunkName: "component---src-pages-components-textarea-usage-mdx" */),
  "component---src-pages-components-time-span-index-js": () => import("./../../../src/pages/components/time-span/index.js" /* webpackChunkName: "component---src-pages-components-time-span-index-js" */),
  "component---src-pages-components-time-span-resources-mdx": () => import("./../../../src/pages/components/time-span/resources.mdx" /* webpackChunkName: "component---src-pages-components-time-span-resources-mdx" */),
  "component---src-pages-components-time-span-usage-mdx": () => import("./../../../src/pages/components/time-span/usage.mdx" /* webpackChunkName: "component---src-pages-components-time-span-usage-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-sink-components-accordion-js": () => import("./../../../src/pages/kitchen-sink/components/accordion.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-accordion-js" */),
  "component---src-pages-kitchen-sink-components-button-js": () => import("./../../../src/pages/kitchen-sink/components/button.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-button-js" */),
  "component---src-pages-kitchen-sink-components-checkbox-group-js": () => import("./../../../src/pages/kitchen-sink/components/checkbox-group.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-checkbox-group-js" */),
  "component---src-pages-kitchen-sink-components-checkbox-js": () => import("./../../../src/pages/kitchen-sink/components/checkbox.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-checkbox-js" */),
  "component---src-pages-kitchen-sink-components-container-js": () => import("./../../../src/pages/kitchen-sink/components/container.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-container-js" */),
  "component---src-pages-kitchen-sink-components-date-picker-js": () => import("./../../../src/pages/kitchen-sink/components/date-picker.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-date-picker-js" */),
  "component---src-pages-kitchen-sink-components-divider-js": () => import("./../../../src/pages/kitchen-sink/components/divider.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-divider-js" */),
  "component---src-pages-kitchen-sink-components-dropdown-js": () => import("./../../../src/pages/kitchen-sink/components/dropdown.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-dropdown-js" */),
  "component---src-pages-kitchen-sink-components-flex-js": () => import("./../../../src/pages/kitchen-sink/components/flex.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-flex-js" */),
  "component---src-pages-kitchen-sink-components-footer-js": () => import("./../../../src/pages/kitchen-sink/components/footer.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-footer-js" */),
  "component---src-pages-kitchen-sink-components-frequency-js": () => import("./../../../src/pages/kitchen-sink/components/frequency.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-frequency-js" */),
  "component---src-pages-kitchen-sink-components-grid-js": () => import("./../../../src/pages/kitchen-sink/components/grid.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-grid-js" */),
  "component---src-pages-kitchen-sink-components-header-js": () => import("./../../../src/pages/kitchen-sink/components/header.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-header-js" */),
  "component---src-pages-kitchen-sink-components-input-js": () => import("./../../../src/pages/kitchen-sink/components/input.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-input-js" */),
  "component---src-pages-kitchen-sink-components-link-js": () => import("./../../../src/pages/kitchen-sink/components/link.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-link-js" */),
  "component---src-pages-kitchen-sink-components-list-js": () => import("./../../../src/pages/kitchen-sink/components/list.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-list-js" */),
  "component---src-pages-kitchen-sink-components-message-js": () => import("./../../../src/pages/kitchen-sink/components/message.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-message-js" */),
  "component---src-pages-kitchen-sink-components-placeholder-js": () => import("./../../../src/pages/kitchen-sink/components/placeholder.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-placeholder-js" */),
  "component---src-pages-kitchen-sink-components-radio-group-js": () => import("./../../../src/pages/kitchen-sink/components/radio-group.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-radio-group-js" */),
  "component---src-pages-kitchen-sink-components-select-js": () => import("./../../../src/pages/kitchen-sink/components/select.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-select-js" */),
  "component---src-pages-kitchen-sink-components-shadow-container-js": () => import("./../../../src/pages/kitchen-sink/components/shadow-container.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-shadow-container-js" */),
  "component---src-pages-kitchen-sink-components-stack-js": () => import("./../../../src/pages/kitchen-sink/components/stack.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-stack-js" */),
  "component---src-pages-kitchen-sink-components-stepper-js": () => import("./../../../src/pages/kitchen-sink/components/stepper.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-stepper-js" */),
  "component---src-pages-kitchen-sink-components-sticky-js": () => import("./../../../src/pages/kitchen-sink/components/sticky.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-sticky-js" */),
  "component---src-pages-kitchen-sink-components-text-js": () => import("./../../../src/pages/kitchen-sink/components/text.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-text-js" */),
  "component---src-pages-kitchen-sink-components-textarea-js": () => import("./../../../src/pages/kitchen-sink/components/textarea.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-textarea-js" */),
  "component---src-pages-kitchen-sink-components-time-span-js": () => import("./../../../src/pages/kitchen-sink/components/time-span.js" /* webpackChunkName: "component---src-pages-kitchen-sink-components-time-span-js" */),
  "component---src-pages-playground-index-js": () => import("./../../../src/pages/playground/index.js" /* webpackChunkName: "component---src-pages-playground-index-js" */),
  "component---src-pages-preview-index-js": () => import("./../../../src/pages/preview/index.js" /* webpackChunkName: "component---src-pages-preview-index-js" */),
  "component---src-pages-spacing-index-js": () => import("./../../../src/pages/spacing/index.js" /* webpackChunkName: "component---src-pages-spacing-index-js" */),
  "component---src-pages-spacing-usage-mdx": () => import("./../../../src/pages/spacing/usage.mdx" /* webpackChunkName: "component---src-pages-spacing-usage-mdx" */),
  "component---src-pages-typography-index-js": () => import("./../../../src/pages/typography/index.js" /* webpackChunkName: "component---src-pages-typography-index-js" */),
  "component---src-pages-typography-usage-mdx": () => import("./../../../src/pages/typography/usage.mdx" /* webpackChunkName: "component---src-pages-typography-usage-mdx" */)
}

